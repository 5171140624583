import React from "react";
import Seo from "../../components/seo";
import PMODashboard from "../../components/Dashboard/pmo";

const LocalDevPage = () => (
  <>
    <Seo title="PMO Dashboard" />
    <PMODashboard />
  </>
);

export default LocalDevPage;
